<template>
  <v-data-table
    :headers="headers"
    :items="getUserList"
    sort-by="calories"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              id="userSearch"
              hide-details
            ></v-text-field
          ></v-col>
          <!-- TODO - Replace these with global modal -->
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit User</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                      label="First Name"
                      required
                      v-model="editedItem.firstName"
                    ></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                      label="Last Name"
                      required
                      v-model="editedItem.lastName"
                    ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                      label="Username"
                      required
                      v-model="editedItem.userName"
                    ></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-select label="Role" required v-model="editedItem.role" :items="roles" item-text="text" item-value="value"></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field label="Phone" required v-model="editedItem.phone"></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field label="Email" required v-model="editedItem.email"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-16 mb-2 ml-2 mr-2">
                      <v-col class="text-right pt-3">
                      <v-btn color="primary" class="mr-4" @click="save"> Save </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="resetDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Reset Password</span>
              </v-card-title>
              <v-row class="ml-2 mr-2">
                <v-col>
                  <v-text-field
                    v-model="tempPassword"
                    flat
                    outline
                    placeholder="Please Enter One Time Password"
                  />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeReset"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="resetPasswordConfirm"
                  >Reset</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      <v-icon small @click="resetPasswordClick(item)"> mdi-lock-reset </v-icon>
    </template>
  </v-data-table>
</template>
<script>
//import UserAdd from "@/components/users/UserAdd.vue";
import { mapActions, mapGetters } from "vuex";
import CONST from '@/const';
export default {
  name: "UserList",
  components: {
    //UserAdd,
  },
  computed: {
    ...mapGetters(["getUserList"]),
     roles(){
      let roleList = [
        { value: CONST.ROLES.MANAGER, text: "Manager" },
        { value: CONST.ROLES.ADMIN, text: "Admin" },
        { value: CONST.ROLES.CALLER, text: "Caller" }
      ]
      return roleList;
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    resetDialog: false,
    search: "",
    tempPassword: "",
    headers: [
      {
        text: "Last Name",
        align: "start",
        value: "lastName",
      },
      {
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      { text: "Username", value: "userName" },
      { text: "Role", value: "role" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchUsers();
  },

  methods: {
    ...mapActions(["fetchUsers", "resetPassword", "updateUser", "deleteUser"]),

    editItem(item) {
      this.editedIndex = this.getUserList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.getUserList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    resetPasswordClick(item) {
      this.editedIndex = this.getUserList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.resetDialog = true;
    },

    deleteItemConfirm() {
      // TODO - use vuex
      this.delete();
    },

    resetPasswordConfirm() {
      this.resetPassword({userId: this.editedItem.id, newPassword: this.tempPassword, userName: this.editedItem.userName });
      this.closeReset();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReset() {
      this.resetDialog = false;
    },

    save() {
      // TODO - Use vuex
      this.editedItem.roles = [];
      this.editedItem.roles.push({name: this.editedItem.role});
      this.updateUser(this.editedItem);
      this.close();
    },

    delete() {
      this.deleteUser(this.editedItem);
      this.closeDelete();
    },
  },
};
</script>