<template>
    <v-form ref="form" v-model="valid" lazy-validation class="ml-12 mr-12 mt-8">
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="Title"
            required
            id="title"
            :rules="titleRules"
            v-model="tenant.title"
          ></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
            <v-text-field
            label="Email"
            required
            id="email"
            :rules="emailRules"
            v-model="tenant.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4"> 
            <v-autocomplete
           
            hide-details
            :return-object="false"
            v-model="tenant.adminUser"
            :items="getUserList"
            clearable
            :persistent-hint="true"
            solo
            :item-text="
              (obj) =>
                obj.firstName +
                ' ' +
                obj.lastName +
                ', ' +
                obj.email
            "
            :item-value="(obj) => obj"
            :label="'Select admin user'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
          
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">  
          
        </v-col>
      </v-row>
      <v-row class="mt-16 mb-2 ml-2 mr-2">
        <v-col class="text-right pt-3">
          <v-btn class="mr-4" @click="clear" id="clearBtn"> Clear </v-btn>
          <v-btn color="primary" class="mr-4" @click="save" id="saveBtn"> Save </v-btn>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TenantAdd",
  data: () => ({
    errors: [],
    selectedUser: null,
    tenant: {
      title: null,
      email: null,
      adminUser: null,
    },
    showErrors: false,
    role: null,
    valid: false,
    titleRules: [
      v => !!v || 'Title is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid Title',
    ],
    emailRules: [ 
      v => !!v || 'An email is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid Email address' 
      ],
  }),
  computed: {
      ...mapGetters(["getUserList"]),
    errorMessages() { return this.errors;},
    getAvailableUsers() {
        return this.getUserList;
    }
  },
  methods: {
    ...mapActions(["fetchUsers", "saveTenant"]),
    save(){
        if (this.$refs.form.validate()) {
          this.saveTenant(this.tenant);
          this.clear();
          this.fetchUsers();
          this.$emit("tenantSaved");
        }
    },
    clear() {
      this.tenant.title = null;
      this.tenant.title = null;
      this.$refs.form.reset();
      this.tenant.email = null;
      this.tenant.adminUser = null;
      

    },
  },
  mounted: function () {
      // `this` points to the view model instance
      //this.user.firstName = this.firstName;
      console.log("users = " + this.getUserList());
    },
  created() {
        this.fetchUsers();
   },
};
</script>

<style scoped>
.error {
  color: #ba3939;
  font-size:1em;
  margin:0px 2px 0px 0px;
}
.invalid {
  border-color: red;
}
</style>