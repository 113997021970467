<template>
  <v-card class="mt-5">
    <v-form ref="form" v-model="valid" lazy-validation class="ml-12 mr-12 mt-8">
      <p v-if="errorMessages.length" class="v-messages error--text">
        <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errorMessages" :key="error">{{ error }}</li>
          </ul>
        </p>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="First Name"
            required
            id="firstNameTxt"
            :rules="firstNameRules"
            v-model="user.firstName"
          ></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="Last Name"
            required
            id="lastNameTxt"
            :rules="lastNameRules"
            v-model="user.lastName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="Username"
            required
            id="usernameTxt"
            :rules="userNameRules"
            v-model="user.userName"
          ></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
          <v-select label="Role" required v-model="role" :items="roles" item-text="text" item-value="value" id="roleSelect" :rules="roleRules"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field label="Phone Number" required v-model="user.phone" id="phoneTxt" :rules="phoneRules"></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">  
          <v-text-field name="email" label="Email" required v-model="user.email" id="emailTxt" :rules="emailRules"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-16 mb-2 ml-2 mr-2">
        <v-col class="text-right pt-3">
          <v-btn class="mr-4" @click="clear" id="clearBtn"> Clear </v-btn>
          <v-btn color="primary" class="mr-4" @click="save" id="saveBtn"> Save </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import CONST from '@/const';

export default {
  name: "UserAdd",
  data: () => ({
    errors: [],
    validation: {
      email: {
        invalid: false
      },
    },
    user: {
      firstName: null,
      lastName: null,
      userName: null,
      roles: [],
      phone: null,
      email: null,
    },
    showErrors: false,
    role: null,
    valid: false,
    emailRules: [ 
      v => !!v || 'An email is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid Email address' 
      ],
    firstNameRules: [
      v => !!v || 'First Name is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid First Name',
    ],
    lastNameRules: [
      v => !!v || 'Last Name is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid First Name',
    ],
    userNameRules: [
      v => !!v || 'Username is required',
    ],
    roleRules: [
      v => !!v || 'A role is required'
    ],
    phoneRules: [
      v => !!v || 'A phone numer is required',
      v => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(v) || 'Invalid Phone Number',
    ],

  }),
  computed: {
    errorMessages() { return this.errors;},
    roles(){
      let roleList = [
        { value: CONST.ROLES.MANAGER, text: "Manager" },
        { value: CONST.ROLES.ADMIN, text: "Admin" },
        { value: CONST.ROLES.CALLER, text: "Caller" }
      ]
      return roleList;
    }
  },
  methods: {
    ...mapActions(["saveUser"]),
    save(){

        if (this.$refs.form.validate()) {
          this.user.roles = [];
          this.user.roles.push({name: this.role});
          this.user.role = this.role;
          this.saveUser(this.user).then(response => {
            this.$emit('userSaved', response);
            this.clear();
          });
        }


    },
    clear() {
      this.user.firstName = null;
      this.user.lastName = null;
      this.user.email = null;
      this.user.phone = null;
      this.user.role = null;
      this.user.roles = [];
      this.userName = null;
      this.user.userName = null;
      this.role = 0;
    },
  },
  mounted: function () {
      // `this` points to the view model instance
      //this.user.firstName = this.firstName;
    }
};
</script>

<style scoped>
.error {
  color: #ba3939;
  font-size:1em;
  margin:0px 2px 0px 0px;
}
.invalid {
  border-color: red;
}
</style>