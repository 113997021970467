<template>
  <v-card class="pa-2">
    <v-tabs vertical class="tabs">
      <v-tab v-if="hasRole(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
        <v-icon left id="templatesBtn"> mdi-bank </v-icon>
        Templates
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
        <v-icon left id="bookBtn"> mdi-bank </v-icon>
        Book Management
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])">
        <v-icon left> mdi-account </v-icon>
        User Management
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])">
        <v-icon left id="customerManagementBtn"> mdi-face-agent </v-icon>
        Customer Management
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])">
        <v-icon left  id="gameColourBtn"> mdi-palette </v-icon>
        Game Colours
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
        <v-icon left id="reportingBtn"> mdi-bank </v-icon>
        Reporting
      </v-tab>
      <v-tab v-if="hasRole(['ROLE_SUPER_ADMIN'])">
        <v-icon left id="tenantManagementBtn"> mdi-bank </v-icon>
        Tenant Management
      </v-tab>
      <v-tab-item v-if="hasRole(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
        <v-card flat>
          <templates />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
        <v-card flat>
          <physical-books />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])">
        <v-card flat>
          <user-home />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])">
        <v-card flat>
          <customer-home/>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])" >
        <v-card flat>
          <system-colours/>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
        <v-card flat>
          <reporting />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasRole(['ROLE_SUPER_ADMIN'])">
        <v-card flat>
          <tenant-home />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Reporting from "@/views/reporting/Reporting.vue";
import PhysicalBooks from "@/views/physical/PhysicalBooks.vue";
import Templates from "@/views/templates/Templates.vue";
import TenantHome from "@/components/tenants/TenantHome.vue";
import UserHome from "@/components/users/UserHome.vue";
import CustomerHome from "@/components/customers/CustomerHome.vue";
import SystemColours from "@/components/systemAdmin/SystemColours.vue";
import { mapActions } from 'vuex';

export default {
  name: "AdminScreen",
  components: {
    UserHome,
    TenantHome,
    SystemColours,
    CustomerHome,
    Templates,
    PhysicalBooks,
    Reporting
  },
  mounted(){
    this.setCurrentPage("Back Office")
  },
  methods: {
    ...mapActions(['setCurrentPage']),
    hasRole (roles) {
      const hasRole = roles.some(role => this.$store.state.auth.roles.includes(role));
      return hasRole;
    },
  }
};
</script>

<style lang="scss" scoped>
div.tabs [role="tab"] {
  justify-content: flex-start;
}
</style>