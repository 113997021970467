<template>
  <v-card class="pa-2">
    <v-tabs centered v-model="selectedTab">
      <v-tab>
        <v-icon id="customersBtn"> mdi-account-multiple </v-icon>
        Customers
      </v-tab>
      <v-tab>
        <v-icon id="addCustomerBtn"> mdi-account-plus </v-icon>
        Add Customer
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <customer-list />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <customer-add  @customerSaved="selectedTab = 0" class="mt-5"/>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import CustomerList from "@/components/customers/CustomerList.vue";
import CustomerAdd from "@/components/customers/CustomerAdd.vue";
export default {
  name: "CustomerHome",
  components: {
    CustomerList,
    CustomerAdd,
  },
   data: () => ({
     selectedTab: 0
   })
};
</script>