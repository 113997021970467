<template>
  <v-card class="pa-2">
    <v-tabs centered class="pl-4 pr-4" v-model="selectedTab">
      <v-tab>
        <v-icon id="users">
          mdi-account-multiple
        </v-icon>
        Users
      </v-tab>
      <v-tab>
        <v-icon id="addUsers">
          mdi-account-plus
        </v-icon>
        Add User
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <user-list/>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <user-add  @userSaved="selectedTab = 0" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import UserList from '@/components/users/UserList.vue'
import UserAdd from '@/components/users/UserAdd.vue'
export default {
    name: "UserHome",
    components: {
      UserList,
      UserAdd
    },
   data: () => ({
     selectedTab: 0
   })
}
</script>