<template>
  <v-data-table
    :headers="headers"
    :items="getCustomerList"
    sort-by="calories"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Customers</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              id="customerSearchBtn"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card class="card">
              <v-card-title>
                <span class="headline">Edit Customer</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                    label="First Name"
                    required
                    v-model="editedItem.firstName"
                    ></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                    label="Last Name"
                    required
                    v-model="editedItem.lastName"
                    ></v-text-field>
                    </v-col>
                  </v-row>
                <v-row>
                  <v-col cols="1" />
                  <v-col cols="9">
                      <v-text-field
                  label="DisplayName"
                  required
                  v-model="editedItem.displayName"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="1" />
                  <v-col cols="9">
                  <v-text-field label="Email" required v-model="editedItem.email" :disabled="noEmail"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="1" />
                  <v-col cols="9">
                  <v-text-field label="Phone" required v-model="editedItem.phone"></v-text-field>
                  </v-col>
                </v-row>  
                <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                      <v-text-field label="Bar Code ID" required v-model="editedItem.barCodeId" id="barCodeId" ></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">  
                    </v-col>
                  </v-row>

                <v-row class="mt-16 mb-2 ml-2 mr-2">
                      <v-col class="text-right pt-3">
                      <v-btn color="primary" class="mr-4" @click="save"> Save </v-btn>
                    </v-col>
                  </v-row>
                
                </v-container>
                
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="resetDialog" max-width="700px">
            <v-card>
              <v-card-title>
                <span class="headline">Reset Password</span>
              </v-card-title>
              <v-row class="ml-2 mr-2">
                <v-col>
                  <v-text-field
                    v-model="tempPassword"
                    flat
                    outline
                    placeholder="Please Enter One Time Password"
                  />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeReset"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="resetPasswordConfirm"
                  >Reset</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      <v-icon small @click="resetPasswordClick(item)"> mdi-lock-reset </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CustomerList",
  components: {
  },
  computed: {
    ...mapGetters(["getCustomerList"]),
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    resetDialog: false,
    search: "",
    tempPassword: "",
    headers: [
      {
        text: "Last Name",
        align: "start",
        value: "lastName",
      },
      {
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Bar Code ID", value: "barCodeId"},
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchCustomers();
  },

  methods: {
    ...mapActions(["fetchCustomers","updateCustomer","deleteCustomer", "resetCustomerPassword"]),

    editItem(item) {
      this.editedIndex = this.getCustomerList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    resetPasswordClick(item) {
      this.editedIndex = this.getCustomerList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.resetDialog = true;
    },

    resetPasswordConfirm() {
      this.resetCustomerPassword({userId: this.editedItem.id, newPassword: this.tempPassword });
      this.closeReset();
    },

    closeReset() {
      this.resetDialog = false;
    },

    deleteItem(item) {
      this.editedIndex = this.getCustomerList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // TODO - use vuex
     // this.desserts.splice(this.editedIndex, 1);
      this.deleteCustomer(this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {

      this.updateCustomer(this.editedItem);
      this.close();
    },
  },
};
</script>