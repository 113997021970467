<template>
<v-form ref="form" v-model="valid" lazy-validation class="ml-12 mr-12">
  <v-card class="pa-2">
    <v-row>
      <v-col cols="3" v-for="colour in getSystemColours" :key="colour.id">
        <colour-picker :value="colour" />
      </v-col>
      <v-col cols="1">
        <v-btn class="ma-2" outlined large fab color="indigo" @click="addSystemColour">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
        <v-spacer/>
        <v-col cols="2">
            <v-btn>Cancel</v-btn>
            <v-btn class="primary ml-2" @click="save">Save</v-btn>
        </v-col>
    </v-row>
  </v-card>
</v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ColourPicker from "@/components/systemAdmin/ColourPicker.vue";

export default {
  name: "SystemColours",
  components: {
    ColourPicker,
  },
  methods: {
    ...mapActions(["fetchColours", "addSystemColour", 'saveColours']),
    save(){

      if (this.$refs.form.validate()) {
          this.saveColours();
      }
    
    },
  },
  computed: {
    ...mapGetters(["getSystemColours"]),
  },
  created() {
    this.fetchColours();
  },
  data: () => ({
    colour: null,
  }),
};
</script>