<template>
    <v-tabs centered class="pl-4 pr-4" v-model="selectedTab">
      <v-tab>
        <v-icon id="tenants">
          mdi-account-multiple
        </v-icon>
        Tenants
      </v-tab>
      <v-tab>
        <v-icon id="addUsers">
          mdi-account-plus
        </v-icon>
        Add Tenant
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <tenant-list/>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <tenant-add @tenantSaved="selectedTab = 0"/>
      </v-tab-item>
    </v-tabs>
</template>

<script>
import TenantList from '@/components/tenants/TenantList.vue'
import TenantAdd from '@/components/tenants/TenantAdd.vue'
export default {
    name: "TenantHome",
    components: {
      TenantList,
      TenantAdd
    },
    data: () => ({
     selectedTab: 0
   })
}
</script>