<template>
  <v-data-table
    :headers="headers"
    :items="getTenantList"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Tenants</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              id="tenantSearch"
              hide-details
            ></v-text-field
          ></v-col>
          <!-- TODO - Replace these with global modal -->
          <v-dialog v-model="dialog" max-width="900px">
            <v-form ref="form" v-model="valid" lazy-validation >
            <v-card class="card">
              <v-card-title>
                <span class="headline">Edit Tenant</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="1" />
                    <v-col cols="4">
                    <v-text-field
                        label="Title"
                        required
                        id="title"
                        :rules="titleRules"
                        v-model="tenant.title"
                         ></v-text-field>
                    </v-col>
                    <v-col cols="1" />
                    <v-col cols="4">
                        <v-text-field
                        label="Email"
                        required
                        id="email"
                        :rules="emailRules"
                        v-model="tenant.email"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                        <v-col cols="1" />
                        <v-col cols="4"> 
                        <v-autocomplete
                            hide-details
                            :return-object="false"
                            v-model="tenant.adminUser"
                            :items="getUpdatedUserList"
                            clearable
                            :persistent-hint="true"
                            solo
                            :item-text="
                            (obj) =>
                            obj.firstName +
                            ' ' +
                            obj.lastName +
                            ', ' +
                            obj.email"
                            :item-value="(obj) => obj"
                            :label="'Select admin user'"
                        ></v-autocomplete>
                        </v-col>
                        <v-col cols="1" />
                        <v-col cols="4">
                         </v-col>
                  </v-row>
                  <v-row>
                  </v-row>
                  <v-row class="mt-16 mb-2 ml-2 mr-2">
                      <v-col class="text-right pt-3">
                        <v-btn color="primary" class="mr-4" @click="save"> Save </v-btn>
                      </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialogDisable" max-width="500px">
            <v-card class="card">
              <v-card-title class="headline"
                >Are you sure you want to disable this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-toolbar>
    </template> 
    <template v-slot:[`item.adminUser`]="{ item }">
      {{ item.adminUser.firstName + " " + item.adminUser.lastName + " (" + item.adminUser.userName + ", " + item.adminUser.email + ")" }} 
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <!--v-icon small disable class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon-->
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TenantList",
  computed: {
    ...mapGetters(["getTenantList", "getUserList"]),
    getUpdatedUserList() {
        var updatedUserList = []
        updatedUserList.push(this.tenant.adminUser)
        updatedUserList.push.apply(updatedUserList, this.getUserList);
        return updatedUserList;
    }
  },
  data: () => ({
    dialog: false,
    dialogDisable: false,
    resetDialog: false,
    search: "",
    tenant: {
      id: null,
      title: null,
      email: null,
      adminUser: null,
    },
    titleRules: [
      v => !!v || 'Title is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid Title',
    ],
    emailRules: [ 
      v => !!v || 'An email is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid Email address' 
    ],
    headers: [
      {
        text: "ID",
        value: "id",
        align: ' d-none'
      },
      {
        text: "Title",
        align: "start",
        value: "title",
      },
      {
        text: "Email",
        align: "start",
        value: "email",
      },
      {
        text: "Admin User",
        align: "start",
        value: "adminUser",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      title: "",
    },
    defaultItem: {
      title: "",
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDisable(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchTenants();
    this.fetchUsers();
  },
  methods: {
    ...mapActions(["fetchTenants", "updateTenant", "disableTenant", "fetchUsers"]),

    editItem(item) {
      this.editedIndex = this.getTenantList.indexOf(item);
      this.tenant = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.getTenantList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDisable = true;
    },

    deleteItemConfirm() {
      // TODO - use vuex
      this.delete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDisable = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReset() {
      this.resetDialog = false;
    },

    save(){
        if (this.$refs.form.validate()) {
          this.updateTenant(this.tenant);
          //this.clear();
          this.fetchUsers();
          this.close();
          this.$emit("tenantSaved");
        }
    },

    delete() {
      this.disableTenant(this.editedItem);
      this.closeDelete();
    },
  },
};
</script>