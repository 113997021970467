<template>
  <v-card>
    <v-card-title>
      <template>
        <v-text-field
          :id="`${value.attributeName}`"
          v-model="value.attributeName"
          :rules="colourNameRules"
        >
        </v-text-field>
      </template>
    </v-card-title>
    <v-row align="center" justify="center">
      <v-color-picker
        v-model="value.attributeValue"
        mode="hexa"
        class="ma-2"
      ></v-color-picker>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ColourPicker",
  data: () =>({
    colourNameRules: [
        v => !!v || 'Colour Name is required',
        v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid Colour Name',
    ],
  }),
  props: {
    value: {
      attributeValue: null,
      attributeName: null,
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
  },
};
</script>